.navDrawer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  background-color: #fff;
  /* background-color: var(--primaryColor); */
  /* background-color: #250e62; */
}

.navDrawer-container .navDrawer-img-container {
  height: 3rem;
  width: 9rem;
}

.ham-container{
  color: var(--secondaryColor);
}

.ham-container:hover {
  cursor: pointer;
}

.ant-drawer-body {
  padding: 0 !important;
}

.ant-drawer-open
  .ant-drawer-content
  .ant-drawer-wrapper-body
  .ant-drawer-body
  .menu
  ul {
  display: flex;
  flex-direction: column;
  padding-left: 0 !important;
}

.ant-drawer-body .menu ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  height: 4rem;
  font-size: 18px;
  color: #250e62;
  border-bottom: 1px solid #c2d7e6;
}

.ant-drawer-body .menu ul li:hover {
  cursor: pointer;
}

.ant-drawer-open
  .ant-drawer-content
  .ant-drawer-wrapper-body
  .ant-drawer-body
  .learn-hover-class {
  display: flex;
  flex-direction: column;
}

.ant-drawer-open
  .ant-drawer-content
  .ant-drawer-wrapper-body
  .ant-drawer-body
  .product-hover-class {
  display: flex;
  flex-direction: column;
}

.ant-drawer-open
  .ant-drawer-content
  .ant-drawer-wrapper-body
  .ant-drawer-body
  .product-hover-class
  .sec-2
  .list-container {
    display: flex;
    flex-direction: column;
}
