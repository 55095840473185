.section-3-container {
  width: 100%;
  max-width: 1170px;
  font-family: Avenir;
  padding: 72px 0 0 0;
  margin-right: auto;
  margin-left: auto;
}

/* .section-3-wrapper {
  width: 100%;
} */

.big-title-section {
  margin-bottom: 128px;
  /* height: 670px; */
}

.big-title-section-heading {
  margin-top: 0;
  font-size: 56px;
  line-height: 64px;
  font-weight: 700;
  text-align: start;
  letter-spacing: -0.01em;
  margin-bottom: 56px;
}

.big-title-section-block-container {
  display: flex;
  /* width: 488px; */
  width: 100%;
  /* flex-direction: column-reverse; */
}

.big-title-section-block-container .div-block-180 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* min-width: 460px;
  max-width: 460px; */
  row-gap: 1rem;
  /* height: 500px; */
}

.big-title-section-block-container .div-block-180 .div-block-182 {
  display: flex;
  flex-direction: column;
}

.div-block-175 {
  display: flex;
  align-items: flex-end;
}

.div-block-175 > img {
  border-radius: 50px;
  width: 50px;
  height: 50px;
}

.div-block-175 .quote {
  /* background-color: #dbebfb; */
  background-color: var(--thirdColor);
  padding: 24px 32px;
  margin-left: 14px;
  margin-bottom: 0;
  border-radius: 24px 24px 24px 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.05em;
}

.div-block-176 {
  padding: 16px 0 0 62px;
  margin-bottom: 16px;
}

.div-block-176 .quote__name {
  font-size: 18px;
  line-height: 28px;
  font-weight: 900;
}

.div-block-176 .quote__text {
  color: #444e5a;
  font-size: 16px;
  line-height: 24px;
}

.div-block-178 {
  padding: 0 0 0 62px;
}

.div-block-178 .link-block-5 {
  width: 100%;
  padding: 16px;
  background-color: #f2f7fb;
  border-radius: 5px;
  font-size: 16px;
  line-height: 24px;
}

.text-block-22 {
  margin-bottom: 12px;
  color: #250e62;
}

.text-block-21 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
}

.slider-img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.div-block-181 {
  /* display: flex; */
  padding-left: 40px;
  width: 100%;
  /* overflow: hidden; */
}

.carousal-row {
  display: flex;
  /* z-index: 2; */
  /* width: 100%; */
}

.div-block-181-inverted {
  display: flex;
  padding-right: 40px;
}

.div-block-180-inverted {
  display: flex;
  flex-direction: column;
  min-width: 460px;
  max-width: 460px;
  row-gap: 1rem;
}

.ant-carousel .slick-slider {
  width: 670px;
  height: 100%;
}

.ant-carousel .slick-slider .slick-list {
  width: 100%;
}

.carousel-class-img > img {
  height: 100%;
  width: 670px;
  color: #fff;
  border-radius: 5px;
  /* // lineHeight: 100%; */
  text-align: center;
  background: #364d79;
}

@media (max-width: 1230px) {
  .ant-carousel {
    /* width: 444px; */
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .div-block-180 {
    align-items: center;
    width: 100%;
  }

  .ant-carousel .slick-slider {
    width: 444px;
    height: 433px;
    display: flex;
    align-items: center;
  }

  .div-block-181 {
    padding-left: 0;
  }

  .div-block-181-inverted {
    padding-right: 0;
  }

  .carousel-class-img > img {
    height: 100%;
    width: 444px;
    color: #fff;
    border-radius: 5px;
    /* // lineHeight: 100%; */
    text-align: center;
    background: #364d79;
  }

  h2 {
    text-align: center;
  }

  .big-title-section-block-container {
    justify-content: center;
  }
  /* .reverse{
    justify-content: center;
  } */
}

@media (max-width: 1024px) {
  .big-title-section-heading {
    margin-top: 0;
    font-size: 48px;
    line-height: 60px;
    font-weight: 700;
    text-align: start;
    letter-spacing: -0.01em;
    margin-bottom: 56px;
  }
  .section-3-container {
    padding: 40px 40px 0 40px;
  }
  .big-title-section {
    margin-bottom: 70px;
  }
}

@media (max-width: 990px) {
  .big-title-section {
    margin-bottom: 40px;
  }
  .big-title-section-heading {
    margin-bottom: 30px;
  }
  .reverse {
    flex-direction: column-reverse;
  }
}

@media (max-width: 768px) {
  .ant-carousel .slick-slider {
    width: 600px;
    /* height: 433px; */
    height: 100%;
    display: flex;
    align-items: center;
  }
  .carousel-class-img > img {
    height: 100%;
    width: 100%;
  }
  .big-title-section-block-container {
    row-gap: 0.8rem;
    align-items: center;
  }
  .div-block-175 {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    row-gap: 1rem;
  }
  .div-block-175 .quote {
    margin-left: 0;
  }
  .div-block-176 {
    padding: 0px 0 0 0px;
    margin-bottom: 16px;
  }
  .div-block-178 {
    padding: 0;
  }
}

@media (max-width: 600px) {
  /* .big-title-section-block-container {
    flex-direction: column-reverse;
  } */
  /* .big-title-section{
    flex-direction: column-reverse;
  } */
  /* .reverse{
    flex-direction: column;
  } */
  .ant-carousel .slick-slider {
    width: 520px;
    /* height: 433px; */
    height: 100%;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 530px) {
  .ant-carousel .slick-slider {
    width: 450px;
  }
}

@media (max-width: 450px) {
  .ant-carousel .slick-slider {
    /* width: 288px; */
    width: 400px;
    /* height: 433px; */
    height: 100%;
    display: flex;
    align-items: center;
  }

  .carousel-class-img > img {
    height: 100%;
    width: 100%;
  }
  .big-title-section-heading {
    margin-bottom: 30px !important;
  }
}

@media (max-width: 425px) {
  .section-3-container {
    padding: 48px 16px 0;
  }
  .ant-carousel .slick-slider {
    width: 350px;
    height: 100%;
  }
  /* .carousel-class-img > img {
    height: 250px;
    width: 250px;
  } */
  .big-title-section-heading {
    margin-top: 0;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    text-align: start;
    letter-spacing: -0.01em;
    margin-bottom: 56px;
  }
}

@media (max-width: 375px) {
  .ant-carousel .slick-slider {
    /* width: 400px; */
    width: 300px;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 320px) {
  .ant-carousel .slick-slider {
    /* width: 400px; */
    width: 280px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .carousel-class-img > img {
    height: 100%;
    width: 100%;
  }
}
