.section-5-container {
  width: 100%;
  padding: 80px 40px 24px 40px;
  /* padding: 80px 0px 24px 0px; */
  /* background-color: #ffcfbe; */
  background-color: var(--thirdColor);
  font-family: Avenir;
}

.section-5-wrapper {
  width: 100%;
  height: auto;
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;
}

.section-5-content-container {
  width: 100%;
  height: auto;
  /* padding: 0 95px; */
}

.section-5-content-heading {
  width: 100%;
  margin-bottom: 16px;
  margin-top: 0;
  color: #250e62;
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  letter-spacing: -0.01em;
  text-align: start;
}

.section-5-content-text {
  z-index: 1;
  width: 80%;
  color: #250e62;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 48px;
}
.cards-column-container {
  width: 100%;
  margin: 0 !important;
  /* display: flex; */
  /* flex-direction: row; */
  /* column-gap: 2rem; */
  /* align-items: flex-start; */
  /* justify-content: center; */
}

/* .cards-col-1 {
  flex: 1;
}
.cards-col-2 {
  flex: 1;
}
.cards-col-3 {
  flex: 1;
} */

.card-img-container {
  width: 100%;
  height: 240px;
  overflow: hidden;
}

.card-img-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.card-text-content {
  padding: 24px 0 16px 0;
}

.card-title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: 0.01em;
  color: rgb(37, 14, 98);
  margin-bottom: 8px;
}

.card-text {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #131313;
}

.section-5-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-5-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #250e62;
  border: 1px solid #250e62;
  border-radius: 5px;
  width: 15rem;
  height: 3rem;
}

.section-5-button:hover {
  cursor: pointer;
  color: white;
  background-color: #f9423a;
  border-color: #f9423a;
}

/* @media (max-width : 1023){
  .section-5-content-text{
    width: 100% !important;
  } 
} */

@media (max-width: 768px) {
  .section-5-container{
    /* padding: 56px 16px 16px 16px; */
    padding: 0px 0px 0px 0px;
  }
  .section-5-content-heading {
    padding: 56px 16px 0px 16px;
    font-size: 24px; 
    line-height: 24px;
    font-weight: 700;
    letter-spacing: -0.01em;
  }
  .section-5-content-text {
    padding: 0px 16px 0px 16px;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 48px;
  }
  .card-img-container > img{
   border-radius: 0px !important; 
  }
  .card-text-content {
    padding: 24px 16px 16px 16px;
  }
  .ant-col{
    padding: 0 !important;
  }
  .section-5-button-container{
    margin-bottom: 2rem;
  }
}
