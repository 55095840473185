@font-face {
    font-family: "avenir";
    src: url("../../assets/fonts/NVZzAQ.otf");
  }

.privacyPolicy {
  padding: 100px 20px;
  background-color: white;
}
.privacyPolicy p {
  font-size: 16px;
  font-family: "avenir", sans-serif;
}
.privacyPolicy .tracker {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
  margin-right: 20px;
}
.privacyPolicy .tracker h1 {
  color: #250e62;
  font-size: 38px;
  width: 735px;
  font-family: "avenir", sans-serif;
}
.privacyPolicy .privacynotice {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
  margin-right: 20px;
}
.privacyPolicy .associated {
  margin-bottom: 50px;
  margin-right: 20px;
  color: #ff4d00;
}
.privacyPolicy .heading {
  font-family: "avenir", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #250e62;
  text-align: center;
}
.privacyPolicy .organization {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-right: 20px;
}
.privacyPolicy .organization h1 {
  font-family: "avenir", sans-serif;
  color: #250e62;
  font-size: 25px;
  font-weight: 900;
}
.privacyPolicy .organization h3 {
  font-family: "avenir", sans-serif;
  color: #250e62;
  font-size: 18px;
  font-weight: 900;
}
.privacyPolicy .line {
  margin: 20px 0;
  height: 3px;
  background-color: rgb(201, 200, 200);
}
.privacyPolicy .Consumer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-right: 20px;
}
.privacyPolicy .Consumer h1 {
  font-family: "avenir", sans-serif;
  color: #250e62;
  font-size: 25px;
  font-weight: 900;
}
.privacyPolicy li {
  margin-bottom: 10px;
  font-size: 16px;
  font-family: "avenir", sans-serif;
  margin-right: 20px;
  list-style: none;
}
@media screen and (max-width: 768px) {
    .privacyPolicy .tracker h1 {
        text-align: center;
    }
    .privacyPolicy .heading {
        margin-bottom: 20px;
    }
}
@media screen and (max-width: 425px) {
    .privacyPolicy .tracker h1 {
        width: unset;
        font-size: 20px;
    }
    .privacyPolicy .tracker, .privacyPolicy .privacynotice, .privacyPolicy .associated, .privacyPolicy .organization, .privacyPolicy li, .privacyPolicy .Consumer  {
        margin-right: 0;
    }
}