.section-2-container {
  width: 100%;
  height: 100%;
  /* height: 440px; */
  /* background-color: #ffcfbe; */
  /* background-color: var(--secondaryColor); */
  background-color: #ffcfbe;
  font-family: Avenir;
  /* padding: 0 0 0 135px; */
}

/* .section-2-wrapper{
    width: 1440px;
    width: 100%;
    height: 440px;
    padding: 0 0 0 135px;
} */

/* .section-2-content-container{
    width : 1770px;
    width: 100%;
    height: 440px;
    padding: 0 0 0 95px;
} */

/* .section-2-content-wrapper {
  width: 1770px;
  width: 100%;
  min-height: 440px;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
} */

.section-2-content-text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: start;
  /* position: absolute; */
  z-index: 1;
  width: 100%;

  height: 100%;
  /* width: 100%; */
  font-size: 32px;
  line-height: 40px;
  /* color: #250e62; */
  color: var(--secondaryColor);
  margin: 0;
}

.section-2-content-text > div {
  max-width: 670px;
}

.section-2-img-container {
  /* width: 810px; */
  width: 100%;
  /* height: 440px; */
  height: 100%;
  /* position: absolute; */
  /* top: 0;
    right: auto;
    bottom: auto;
    left: 496px; */
}

.section-2-img-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 1800px) {
  .section-2-img-container {
    width: 810px;
  }
}

@media (max-width: 1024px) {
  .section-2-container {
    padding: 20px 0 0 0px !important;
  }

  .section-2-content-text {
    justify-content: center;
    font-size: 24px;
  }

  .section-2-content-text > div {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .section-2-content-text {
    font-size: 24px;
  }
}

@media (max-width : 425px){
  .section-2-container {
    padding: 30px 0 0 16px !important;
  }
  .section-2-content-text{
    line-height: 30px;
    font-size: 18px;
  }
}
