.section-4-container {
  background-color: #f2f7fb;
  padding: 72px 40px;
  font-family: Avenir;
}

.section-4-wrapper {
  width: 100%;
  height: auto;
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;
}

.section-4-wrapper .div-block-174 {
  /* padding: 72px 0; */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* justify-content: center; */
}

.section-4-left-side {
  width: 100%;
  max-width: 570px;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.section-4-heading {
  /* margin-bottom: 16px; */
  color: #250e62;
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  letter-spacing: -0.01em;
  text-align: start;
}

.section-4-text {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.section-4-img-container {
  /* width: 470px; */
  width: 100%;
}

.img-24 {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.section-4-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #250e62;
  border: 1px solid #250e62;
  border-radius: 5px;
  width: 18rem;
  height: 3rem;
}

.section-4-button:hover {
  cursor: pointer;
  color: white;
  background-color: #f9423a;
  border-color: #f9423a;
}

/* .img-25{
    position: absolute;
    left: auto;
    top: auto;
    right: -88px;
    bottom: 0;
    width: 308px;
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
} */

@media (max-width: 768px) {
  .section-4-heading {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: -0.01em;
    text-align: start;
  }
  .section-4-img-container{
    margin-top: 2rem;
  }
}

@media (max-width : 425px){
  .section-4-container{
    padding: 72px 16px 16px 16px;
  }
}

@media (max-width: 376px) {
  .section-4-button {
    width: 15rem;
  }
}
