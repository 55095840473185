.section-6-container {
  width: 100%;
  padding: 64px 40px 64px 40px;
  /* background-color: #250e62; */
  background-color: var(--primaryColor);
  /* background-image: url(https://assets-global.website-files.com/5e14095…/5f3bc65…_footer-cta-pattern-right.svg),
    url(https://assets-global.website-files.com/5e14095…/5f3bc6d…_footer-cta-pattern-left.svg); */
  background-repeat: no-repeat;
  font-family: Avenir;
}

.section-6-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-6-title {
  color: #fff;
  margin-bottom: 24px;
  font-size: 36px;
  line-height: 48px;
  font-weight: 500;
}

.section-6-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-6-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
  background-color: #f9423a;
  border: 1px solid #f9423a;
  border-radius: 5px;
  width: 10rem;
  height: 3rem;
}

.section-6-button:hover {
  cursor: pointer;
  color: white;
  background-color: #de554e;
  border-color: #de554e;
}

@media(max-width : 500px){
  .section-6-button{
    width: 8rem;
    font-size: 14px;
  }
  .section-6-title{
    font-size: 24px;
  }
}

@media(max-width : 320px){
  .section-6-title{
    font-size: 20px;
  }
}
