.section-one-container {
  padding: 112px 135px 40px 135px;
  /* background-color: #250e62; */
  background-color: var(--primaryColor);
  background-image: url(../../assets/imagesfireup/SLIDER_01.png);
  background-repeat: no-repeat;
  background-position: 75% 15%;
  background-size: 80%;
  /* background-color: #ef5a29; */
  width: 100%;
  height: 100%;
  font-family: Avenir;
  display: flex;
  justify-content: center;
}

.section-one-content-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 14%;
}

.section-one-content-wrapper {
  /* width: 570px; */
  /* height: 312px; */
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.section-one-content-wrapper .content-heading {
  font-family: Recoleta;
  line-height: 44px;
  letter-spacing: 0.01em;
  font-size: 40px;
  /* color: #623423; */
  /* color: #ef5a29; */
  color: var(--secondaryColor);
}

.content-text {
  margin-top: 0;
  margin-right: 32px;
  margin-bottom: 32px;
  margin-left: 0;
  font-size: 20px;
  color: #ffffff;
  letter-spacing: 0.005em;
  line-height: 24px;
  text-align: start;
}

.button-container {
  /* min-width: 169px;
  max-width: 169px;
  min-height: 48px;
  max-height: 48px; */
  width: max-content;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f9423a; */
  background-color: var(--secondaryColor);
  /* background-color: #f8b917; */
  border-radius: 5px;
}

.button-container:hover {
  background-color: #f2564e;
  /* background-color: #f8b917; */
}

.button-container a {
  text-decoration: none;
  font-size: 14px;
}

.logos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 40px 0px;
}

.logos-container img {
  width: 180px;
  height: 120px;
  margin: 0 30px;
  /* width: 120px;
  height: 100px; */
}

/* .logos-container img > img{
  color: #ef5a29;
} */

@media (max-width: 1024px) {
  .section-one-container {
    padding: 80px 40px 40px 40px;
  }
}

@media(max-width: 770px) {
  .logos-container {
    /* width: 220px;
    height: 120px; */
    /* width: 200px; */
    justify-content: center;

  }
  .logos-container img {
    /* width: 220px;
    height: 120px; */
    /* width: 200px; */
    margin: 0 20px;

  }
  .section-one-container {
    padding: 40px 16px 0 16px;
    background-position: 118% 15%;
  }

  .section-one-content-wrapper .content-heading {
    font-size: 30px;
  }

  .section-one-content-wrapper .content-text {
    font-size: 18px;
  }

@media (max-width: 550px){
  .section-one-container{
    background-image: none;
  }
  .logos-container {
padding: 30px 0;
    justify-content: center;

  }
}

@media (max-width: 425px) {
  .section-one-content-container {
    margin-bottom: 0;
  }
}

@media (max-width: 422px) {
  .section-one-content-wrapper .content-text {
    font-size: 16px;
  }

  /* .logos-container {
    justify-content: center;
  }

  .logos-container img {
    width: 150px;
  } */
}

/* @media (max-width: 332px) {
  .logos-container {
    justify-content: space-evenly;
  }
  .logos-container img {
    width: 100px;
  }
} */

/* #F8B917 yellow shade */
/* #ef5a29 orange shade */
/* #fec60aab pruple shade */
}