.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  padding-left: 8rem;
  font-family: Avenir;
  /* background-color: #250e62; */
  /* background-color: var(--primaryColor); */
  background-color: #fff;
  /* background-color: #ef5a29; */
}

.nav-sec-1 {
  display: flex;
  align-items: center;
  height: 100%;
}

.logo {
  height: 3rem;
  width: 8rem;
  margin-bottom: 10px;
}

.logo img {
  height: 100%;
  width: 100%;
  /* background-color: #250e62; */
}

.menu ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
  margin: 0;
}

.menu ul li {
  position: relative;
  font-size: 16px;
  /* display: flex;
  align-items: center; */
  color: var(--secondaryColor);
  /* color: white; */
  /* color: #D4E3ED; */
  /* color: #ef5a29; */
}

.menu ul li > .drop-down-container {
  display: none;
}

.menu ul li:hover > .drop-down-container {
  display: block;
  top: 100%;
}

.menu:hover {
  cursor: pointer;
}

.menu ul > .btn {
  display: flex;
  align-items: center;
}

.learn-hover-class {
  display: flex;
  height: 100%;
}

.learn-hover-class > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 0.8rem;
  padding: 2rem 1rem 1rem 1rem;
}

.learn-hover-class .drop-hover-class .drop-sec-icon {
  color: var(--thirdColor);
  font-size: 20px;
}

.learn-hover-class .drop-hover-class .drop-sec-title {
  color: var(--thirdColor);
}

.learn-hover-class .drop-hover-class:hover .drop-sec-title {
  color: var(--secondaryColor) !important;
}

.learn-hover-class .drop-hover-class:hover .drop-sec-icon {
  color: var(--secondaryColor) !important;
}

.product-hover-class {
  display: flex;
  height: 100%;
}

.sec-1 {
  flex: 1;
  padding: 28px 20px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.product-hover-class .sec-1:hover .drop-sec-title {
  color: var(--secondaryColor);
}

.sec-2 {
  flex: 4;
  padding: 28px 20px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.product-hover-class .sec-2:hover .drop-sec-title {
  color: var(--secondaryColor);
}

.product-hover-class .sec-1 > ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 0.5rem;
  padding: 0;
}

.product-hover-class .sec-1 > ul > li {
  color: black;
  font-size: 14px;
  transition: all 0.3s ease;
}

.product-hover-class .sec-1 > ul > li:hover {
  font-size: 16px;
  cursor: pointer;
}

.product-hover-class .sec-2 .list-container > ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 0.5rem;
  padding: 0;
}

.product-hover-class .sec-2 .list-container {
  display: flex;
  justify-content: space-between;
}

.product-hover-class .sec-2 .list-container > ul > li {
  color: black;
  font-size: 14px;
  transition: all 0.3s ease;
}

.product-hover-class .sec-2 .list-container > ul > li:hover {
  font-size: 16px;
  cursor: pointer;
}

.product-hover-class .drop-hover-class .drop-sec-title {
  font-size: 11px;
  color: var(--thirdColor);
}

.nav-sec-2 {
  display: flex;
  column-gap: 1rem;
  /* align-items: center; */
  height: 100%;
}

.nav-sec-2 a {
  font-size: 16px;
  height: 100%;
  display: flex;
  align-items: center;
  /* color: white; */
  color: var(--secondaryColor);
}

.nav-sec-2 a:hover {
  cursor: pointer;
  color: var(--primaryColor);
}

.nav-sec-2 span {
  height: 100%;
  display: flex;
  align-items: center;
  /* color: white; */
  color: var(--secondaryColor);
  /* color: #d4e3ed; */
}

.nav-sec-2 span:hover {
  cursor: pointer;
  color: var(--primaryColor);
}

.nav-sec-2 log-btn {
  background-color: #ef5a29; /* Or any color you prefer */
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px; /* Add spacing between name and button */
}

.nav-sec-2 .log-btn:hover {
  background-color: #d44e24; /* Darker shade on hover */
}

.user-info {
  display: flex;
  align-items: center;
}

.welcome-text {
  margin-right: 15px;
  font-size: 16px;
}

.logout-btn {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.logout-btn:hover {
  background-color: #d32f2f;
}

@media (max-width: 1025px) {
  .nav-container {
    padding-left: 1rem;
  }
}
