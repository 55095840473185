.footer-container {
  /* padding: 0 40px;
  padding: 0 95px; */
  padding: 32px 135px 0 135px;
  font-family: Avenir;
}

.footer-wrapper {
  /* padding: 0 95px; */
  width: 100%;
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;
}

.footer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  margin-bottom: 40px;
}

.footer-header-left {
  display: flex;
  justify-content: flex-start;
  column-gap: 1rem;
}

.footer-header-left .text-block-35 {
  font-size: 14px;
  color: #456682;
}

.footer-header-right {
  display: flex;
  column-gap: 2rem;
}

.footer-content {
  display: flex;
  column-gap: 64px;
}

.footer-content-left {
  display: flex;
  column-gap: 16px;
  flex: 3;
  border-right: 0.005em solid #c2d7e6;
}

.footer-left-content-title {
  margin-bottom: 3px;
  color: #5780a3;
  font-size: 12px;
  text-transform: uppercase;
}

.footer-content-left .col-1 {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  flex: 3;
}

.col-1-content-1 {
  color: #5780a3;
  font-size: 16px;
  line-height: 24px;
}

.col-1-content-2 {
  color: #5780a3;
  font-size: 16px;
  line-height: 24px;
}

.footer-left-content-links > div {
  padding: 4px 0;
}

#link:hover{
    cursor: pointer;
    color: #F9423a;
}

.footer-content-left .col-2 {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  flex: 4;
}

.col-2-content-1 {
  color: #5780a3;
  font-size: 16px;
  line-height: 24px;
}

.col-2-content-2 {
  color: #5780a3;
  font-size: 16px;
  line-height: 24px;
}

.footer-content-right {
    display: flex;
    flex: 4;
    column-gap: 32px;
}

.footer-content-right .col-1{
    flex: 1;
}

.footer-content-right .col-2{
    flex: 1;
}

.footer-right-content-title {
  margin-bottom: 3px;
  color: #5780a3;
  font-size: 12px;
  text-transform: uppercase;
}

.footer-right-col-1-content-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 6px 0 0 0;
}

.content-sec-1:hover{
    cursor: pointer;
}

.content-sec-1:hover .content-link{
    color: #F9423a;
}

.content-sec-2:hover{
    cursor: pointer;
}

.content-sec-2:hover .content-link{
    color: #F9423a;
}

.content-sec-3:hover{
    cursor: pointer;
}

.content-sec-3:hover .content-link{
    color: #F9423a;
}

.footer-right-col-1-content-wrapper .content-title {
  margin-bottom: 4px;
  color: #250e62;
  font-size: 14px;
  line-height: 20px;
  font-weight: 900;
}

.footer-right-col-1-content-wrapper .content-text {
  color: #456682;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.footer-right-col-1-content-wrapper .content-link{
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 900;
    color: #5780a3;
    
} 

.footer-right-card-container{
    display: flex;
    flex-direction: column;
    row-gap: 0.7rem;
    padding: 6px 0 0 0;
}

.footer-right-card{
    display: flex;
    column-gap: 16px;
}

.footer-right-card{
    color: #5780a3;
}

.footer-right-card:hover{
    cursor: pointer;
}

.footer-right-card:hover .text-block-39{
    color: #F9423a;
}

.col-2-img-container{
    max-width: 80px;
    height: 60px;
    overflow: hidden;
}

.col-2-img-container > img{
    object-fit: cover;
    border-radius: 4px;
}

.text-block-38{
    display: flex;
    align-items: center;
    color: #5780a3;
    margin-top: 10px;
}

.text-block-38:hover{
    cursor: pointer;
    color: #F9423a;
}

@media(max-width : 1024px){
  .footer-container{
    padding: 32px 40px 0 40px;
  }
}

@media(max-width : 768px){
  .footer-container{
    padding: 32px 24px 0 24px;
  }
  .footer-content {
    display: flex;
    flex-direction: column-reverse;
    /* column-gap: 64px; */
    row-gap: 1.5rem;
  }
  .footer-content-left{
    border-right: none;
    /* border-top: 0.005em solid #c2d7e6; */
  
  }
  .footer-content-left .col-2 .col-2-content-1{
    display: none;
  }
  .footer-content-left .col-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    row-gap: 24px;
    flex: 1;
  }
  .footer-content-left .col-2{
    flex: 1;
    align-items: flex-end;
  }
  .footer-content-right .col-1{
    display: none;
  }
}
@media(max-width : 425px){
  .footer-header{
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1.5rem;
  }
  .footer-header-left{
    flex-direction: column;
    row-gap: 1.5rem;
  }
  .footer-content-left{
    flex-direction: column;
    row-gap: 1.5rem;
  }
  .footer-content-left .col-1 {
    justify-content: flex-start;
    column-gap: 25%;
  }
  .footer-content-left .col-2{
    align-items: flex-start;
  }
}