.section-extra-one-container {
  /* width: 100%; */
  max-width: 1170px;
  /* padding: 50px 135px 72px 135px; */
  padding: 50px 0px 72px 0px;
  font-family: Avenir;
  margin-right: auto;
  margin-left: auto;
}

.section-extra-one-wrapper {
  width: 100%;
  /* padding: 0 95px; */
  display: flex;
  flex-direction: column;
  row-gap: 5rem;
}

.section-extra-one-wrapper .heading-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.section-extra-one-wrapper .heading-container > div {
  font-size: 56px;
}

.section-extra-content-container {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.section-extra-content-container-reverse{
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.section-extra-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 15px;
  /* padding-left: 100px; */
}

.section-extra-content > div{
  margin-left: 80px;
}

.section-extra-content .content-l1 {
  font-size: 15px;
  letter-spacing: 0.3rem;
}

.section-extra-content .content-l2 {
  font-size: 23px;
  letter-spacing: 0.1rem;
  color: #250e62;
}

.section-extra-content .content-l3 {
  font-size: 18px;
  width: 450px;
  color: #456682
}

.section-extra-img-container {
  flex: 1;
  /* width: 540px;
  height: 334px; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0 30px 0 30px;
  box-shadow: 10px 10px 20px #8c8c8c;
}

.section-extra-img-container > img {
  width: 100%;
  /* height: 334px; */
  height: 100%;
  object-fit: fill;
  border-radius: 0 30px 0 30px;
  
}

@media(max-width : 1024px){
  .section-extra-one-container{
    padding: 50px 40px 72px 40px;
  }

  .section-extra-content{
    
  }
  .section-extra-content .content-l3{
    width: 80%;
  }
  .section-extra-content-reverse .content-l3{
    width: 80%;
  }
  /* .section-extra-img-container{
    flex: 3;
  } */
}

@media(max-width : 890px){
  .section-extra-content-container{
    flex-direction: column;
    row-gap: 4rem;
  }
  .section-extra-content-container-reverse{
    flex-direction: column-reverse;
    row-gap: 4rem;
  }
}

@media (max-width : 768px){
  .section-extra-one-container{
    padding: 10px 40px 72px 40px;
  }
  .section-extra-one-wrapper{
    row-gap: 3rem;
  }
  .section-extra-content > div{
    margin: 0;
  }
  .section-extra-content > div > .content-l3{
    width: 100%;
  }
}

@media (max-width : 425px){
  .section-extra-one-wrapper{
    row-gap: 3rem;
  }
  .section-extra-one-container{
    padding: 10px 16px 50px 16px;
  }
  .section-extra-one-wrapper .heading-container > div{
    font-size: 40px;
  }
}

@media (max-width : 352px){
  .section-extra-one-wrapper .heading-container > div{
    font-size: 30px;
  }
}